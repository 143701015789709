<!--
1. Frage: Inventar ja oder nein? -> Radiobutton
  -> Wenn ja: Gesamtkaufpreis, davon entfällt auf Inventar (in neuer Zeile) -> Beträge in EUR, Textfelder
  -> Wenn nein: Nur Kaufpreis Textfeld

2. Frage: Wie erfolgt die Übertragung? -> Radiobutton -> Antworten: Geldlastenfrei oder  "Verbindlichkeiten werden mitübernommen" (Genauer Text folgt, Tooltipp muss möglich sein)
-> Wenn Verbindlichkeiten übernommen: Textfeld C-Laufende Nr. (Info: Irgend eine Nummer aus Grundbuch, 3stellig Integer)
-> Wenn nein: Nix machen

3. Frage: Werden sonstige Lasten (nicht Geldlasten) gelöscht? -> Radiobutton -> Antworten -> ja oder nein
-> Wenn ja: Textfeld C-Laufende Nr.
-> Wenn nein: Nix machen

4. Frage: Bestehen außerbücherliche Rechte? -> Radiobutton -> Antworten -> ja oder nein -> Nein als Defaultwert
-> Wenn ja 3 Varianten:
    - Mietverhältnis wird übernommen -> Textfeld -> Label: Bitte beschreiben Sie das Mietverhältnis
    - Dienstbarkeit (nicht im Grundbuch) wird übernommen -> Textfeld -> Label: Bitte beschreiben Sie die Dienstbarkeit
    - Sonstige, nicht im Grundbruch eingetragene Lasten -> Textfeld -> Label: Bitte beschreiben sie die Last
-> Wenn nein: nix

5. Abfrage Übergabedatum: Datepicker

6. Bitte wählen Sie den Umfang der Gewährleistung (Dropdown): Neutral, Käuferfreundlich, Verkäuferfreundlich, Verbrauchergeschäft -> Achtung: kein Defaultwert angeben!

7. Wird der Kaufvertrag zwischen Begünstigten (nahen Angehörigen) abgeschlossen? -> Radiobutton,  Tooltipp: Definiert nahe Angehörige und nennt Gesetz
 - ja
 - nein
 - teilweise (nur wenn mehrere Käufer oder Verkäufer) -> Verlinkung auf Schritt momentan noch nicht wichtig, aber nice2have

8. Liegt eine Ausnahme zur Immobilienertragssteuer vor? -> Dropdown
Auswahl: keine Befreiung, Hauptwohnsitzbefreiung 2 Jahre, Hauptwonsitzbefreiung 5 aus 10, Herstellerbefreiung, Verkäufer Körperschaft
 -> Tooltipp erklärt das Ganze dann genauer

9. Wurde die ursprüngliche Baubewilligung des Gebäudes oder eines Teiles des Gebäudes vor dem 08.05.1945 erteilt? -> Radiobutton
 -> ja
 -> nein
 -> weiss nicht

----------------- Ab hier wirds kompliziert :-) ----------

10. Werden sonstige Rechte eingeräumt? -> Checkbox
- Wohnrecht
- Fruchtgenussrecht
- Belastungsverbot
- Veräußerungsverbot
- Belastungs- und Veräußerungsverbot
- Vorkaufsrecht
- Wiederkaufsrecht
- Sonstiges: Textfeld
-> Wenn eine Bedingung zutriff (ausser Sonstiges Textfeld), dann Frage einblenden: Wem ist dieses Recht einzuräumen?
-> Checkboxen, da eine oder mehrere ausgewählt werden können
-> Button vorsehen für "Weitere Person hinzufügen" (es können mehrere hinzugefügt werden) -> dann wird abgefragt wie bei Verkäufer^^^

11.) Wenn Belastungsverbot, Veräusserungsverbot oder Belastungs und Veräusserungsverbot vorliegt, dann:
  -> Frage: Sind die Berechtigten nahe Angehörige im Sinne des Gesetzes?
   -> Wenn ja: nix machen
   -> Wenn nein: nix machen
-->

<!-- <div (window:beforeunload)="onBeforeUnload()"></div> -->

<div class="container mb-5 wegLogic">
  <h3>Allgemeines </h3>
  <form class="example-form" #contractForm="ngForm">

    <!-- Miet Section Start -->
    <div *ngIf="this.miet">
      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && wegLogicData.mietObjType == ''" [class.validation-error]="submitted">
          <mat-label>Art des Objekts</mat-label>
          <mat-select required [(ngModel)]="wegLogicData.mietObjType" (selectionChange)="mietzinschange($event, 'art')" name="mietObjType">
            <mat-option *ngFor="let asdf of mietObjTypeOptionen" [id]="asdf.viewValue" [value]="asdf.value">
              {{asdf.viewValue}}
            </mat-option>
            <mat-option *ngIf="contractOption=='BBL'" value="sonstigeflaeche">sonstige unbebaute Fl&auml;che
            </mat-option>
            <mat-option *ngIf="contractOption=='BBL'" value="lwpachtvertrag">landwirtschaftlicher Pachtvertrag
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Pachtgegenstand bebaut -->
      </div>

      <div class="radio-left-right mt-3" *ngIf="wegLogicData.mietObjType == 'lwpachtvertrag'">
        <div class="row">
          <div class="col-6">
            <p class="example-radio-group-label">Ist der Pachtgegenstand bebaut?</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.lwbebaut" name="lwbebaut" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="radio-left-right mt-3 mb-2" *ngIf="wegLogicData.mietObjType == 'parkplatz' || wegLogicData.mietObjType=='sonstigeflaeche' || wegLogicData.mietObjType=='lwpachtvertrag'">
        <div class="row">
          <div class="col-6">
            <!-- Gewerblich -->
            <p class="example-radio-group-label">Nutzt die Mieterseite den Mietgegenstand gewerblich?</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.gewerblich" name="gewerblich" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>

      <!-- Welche Räume -->
      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && wegLogicData.mietRaeume == ''" [class.validation-error]="submitted">
          <mat-label>Beschreiben Sie den Mietgegenstand</mat-label>

          <input *ngIf="wegLogicData.parkplatz" placeholder="z.B. Parkplatz Top 5." required matInput
            [(ngModel)]="wegLogicData.mietRaeume" name="mietRaeume" pattern="^[^\s]+(\s+[^\s]+)*$">

          <input *ngIf="wegLogicData.mietObjType == 'geschaeftsraum' || wegLogicData.mietObjType == 'unternehmenspacht'"
            placeholder="z.B. drei Büros und Abstellfläche" required matInput [(ngModel)]="wegLogicData.mietRaeume"
            name="mietRaeume" pattern="^[^\s]+(\s+[^\s]+)*$">

          <input *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'"
            placeholder="z.B. Liegenschaftsfläche gem. beigeschlossenem Lageplan" required matInput
            [(ngModel)]="wegLogicData.mietRaeume" name="mietRaeume" pattern="^[^\s]+(\s+[^\s]+)*$">

          <input *ngIf="wegLogicData.mietObjType != 'sonstigeflaeche' && !wegLogicData.parkplatz
            && !(wegLogicData.mietObjType == 'geschaeftsraum' || wegLogicData.mietObjType == 'unternehmenspacht')"
            placeholder="z.B. K&uuml;che, Schlafzimmer, Parkplatz etc." required matInput
            [(ngModel)]="wegLogicData.mietRaeume" name="mietRaeume" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
      </div>

      <h3>Mietobjekt</h3>
      <!-- Anschrift -->
      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && (!wegLogicData.strasse || wegLogicData.strasse == '')" [class.validation-error]="submitted">
          <mat-label>Stra&szlig;e</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.strasse" name="strasse" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && (!wegLogicData.plz || wegLogicData.plz == '')" [class.validation-error]="submitted">
          <mat-label>PLZ</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.plz" name="plz" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Ort</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.ort" name="ort" [class.validation-error]="submitted">
        </mat-form-field>
        <!-- Nutzfläche -->
        <mat-form-field appearance="none" class="no-padding no-line" *ngIf="wegLogicData.mietObjType != 'parkplatz'" [class.validation-error]="submitted">
          <mat-label>Nutzfl&auml;che des Mietobjekts in m²</mat-label>
          <input required pattern="^[0-9]+$|^[0-9]+,[0-9]+$" matInput [(ngModel)]="wegLogicData.nutzflaeche"
            name="nutzflaeche">
        </mat-form-field>
      </div>
      <!-- Nutzfläche -->

      <!-- Wohnung oder Geschäftsräumlichkeit -->
      <div *ngIf="wegLogicData.mietObjType == 'wohnung' || wegLogicData.mietObjType=='geschaeftsraum'">
        <div class="col-12">
          <div class="yellow-line-2 mt-4 mb-2"></div>
        </div>
        <!-- 2>Miet Objekte -->

        <div class="mt-3">
          <div class="radio-left-right">
            <div class="row">
              <div class="col-6">
                <p>Sind auf der Liegenschaft mehr als zwei vermietbare Objekte errichtet?</p>
              </div>
              <div class="col-6">
                <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="wegLogicData.mehrals2VermietbareObj" name="mehrals2VermietbareObj" [class.validation-error]="submitted">
                  <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                    {{asdf}}&emsp;
                  </mat-radio-button>&emsp;
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="wegLogicData.mehrals2VermietbareObj == 'Ja'">
          <!-- ursprüngliche Baubewilligung -->
          <div>
            <div *ngIf="contractOption == 'WEG'" class="custom-box">
              <label style="width: 80%;">Wurde die ursprüngliche Baubewilligung des Gebäudes<br> oder eines Teiles des
                Gebäudes
                <b>vor</b> dem 08.05.1945
                erteilt? </label>
              <fa-icon [icon]="faInfoCircle" matTooltipPosition="above"
                matTooltip="Das Datum der Errichtung des Gebäudes ist bei der Beurteilung, inwiefern bei Vermietungen das Mietrechtsgesetz zur Anwendung gelangt, von erheblicher Bedeutung. Insofern die Baubewilligung zur Errichtung des Gebäudes vor dem Stichtag erteilt wurde, könnte die Höhe des Mietzinses begrenzt sein.">
              </fa-icon>
              <br />
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.bewilligungVor08051945" name="bewilligungVor08051945" [class.validation-error]="submitted">
                <mat-radio-button (change)="bewillChange($event)" class="example-radio-button"
                  *ngFor="let bewilligungVor08051945 of MietBewilligungVor08051945Optionen"
                  [value]="bewilligungVor08051945">
                  {{bewilligungVor08051945}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
            </div>

            <div *ngIf="contractOption == 'BBL'" class="custom-box">
              <label style="width: 80%;">Wurde die ursprüngliche Baubewilligung des Gebäudes<br> oder eines Teiles des
                Gebäudes
                <b>vor</b> dem 30.06.1953
                erteilt? </label>
                <fa-icon [icon]="faInfoCircle" matTooltipPosition="above"
                  matTooltip="Das Datum der Errichtung des Gebäudes ist bei der Beurteilung, inwiefern bei Vermietungen das Mietrechtsgesetz zur Anwendung gelangt, von erheblicher Bedeutung. Insofern die Baubewilligung zur Errichtung des Gebäudes vor dem Stichtag erteilt wurde, könnte die Höhe des Mietzinses begrenzt sein.">
                </fa-icon>
              <br />
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.bewilligungVor08051945" name="bewilligungVor08051945" [class.validation-error]="submitted">
                <mat-radio-button (change)="bewillChange($event)" class="example-radio-button"
                  *ngFor="let bewilligungVor08051945 of MietBewilligungVor08051945Optionen"
                  [value]="bewilligungVor08051945">
                  {{bewilligungVor08051945}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
            </div>
          </div>

          <!-- Baujahr < 45 -->
          <div *ngIf="wegLogicData.bewilligungVor08051945 == 'Ja' && contractOption == 'BBL'" class="custom-box">
            <p>Wurde die ursprüngliche Baubewilligung des Gebäudes
              oder eines Teiles des Gebäudes <b>vor</b> dem 08.05.1945 erteilt?</p>
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.zusatzfragebj45" name="zusatzfragebj45" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group><br>
            <br>
          </div>

          <!-- Denkmalschutz -->
          <div *ngIf="wegLogicData.bewilligungVor08051945 == 'Ja'">
            <div *ngIf="contractOption == 'WEG'" class="custom-box">
              <p>Am Gebäude besteht Denkmalschutz und wurden zur Erhaltung des Gebäudes nach dem <b>08.05.1945</b>
                erhebliche
                Eigenmittel aufgewendet?</p>
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.denkmalschutz" name="denkmalschutz" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                  {{asdf}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
              <br>
              <br>
            </div>
            <div *ngIf="contractOption == 'BBL'" class="custom-box">
              <p>Am Gebäude besteht Denkmalschutz und wurden zur Erhaltung des Gebäudes nach dem <b>08.05.1945</b>
                erhebliche Eigenmittel aufgewendet?</p>
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.denkmalschutz" name="denkmalschutz" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                  {{asdf}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
              <br>
            </div>
          </div>

          <!-- Kategorie -->
          <div *ngIf="wegLogicData.mietObjType != 'geschaeftsraum' && wegLogicData.denkmalschutz != 'Ja' &&
           (wegLogicData.bewilligungVor08051945 == 'Ja' && contractOption=='WEG' || wegLogicData.zusatzfragebj45=='Ja'
           && contractOption=='BBL')" class="custom-box">
            <p>Welche Kategorie hat der Mietgegenstand?</p>
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.kategorieHelper" name="kategorie" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of mietKategorieOptionen" [value]="asdf.value">
                {{asdf.viewValue}}&emsp;
              </mat-radio-button>
              &emsp;
            </mat-radio-group><br>
            <br>
          </div>

          <!-- Räumung -->
          <div *ngIf="wegLogicData.denkmalschutz != 'Ja' && (wegLogicData.kategorieHelper == 'A' || wegLogicData.kategorieHelper == 'B') && wegLogicData.nutzflaeche > 130">
            <div class="custom-box">
              <p>Wird der Mietgegenstand innerhalb von sechs Monaten nach Räumung durch den Vormieter oder Inhaber zur
                Neuvermietung vermietet?</p>
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.raeumung6monate " name="raeumung6monate" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                  {{asdf}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
              <br>
            </div>
          </div>
        </div>
      </div>

      <!-- Lage -->
      <div *ngIf="wegLogicData.mietObjType!='geschaeftsraum' &&(contractOption == 'WEG' || contractOption == 'BBL' && wegLogicData.zusatzfragebj45 == 'Ja') && wegLogicData.denkmalschutz != 'Ja' &&  wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.bewilligungVor08051945 == 'Ja' && (wegLogicData.raeumung6monate == 'Nein' || wegLogicData.kategorieHelper=='C')" class="custom-box">
        <p>Befindet sich der Mietgegenstand in einer &uuml;berdurchschnittlich guten Lage?</p>
        <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
          [(ngModel)]="wegLogicData.lagezuschlag" name="lagezuschlag" [class.validation-error]="submitted">
          <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
            {{asdf}}&emsp;
          </mat-radio-button>&emsp;
        </mat-radio-group><br>
        <br>
        <mat-form-field *ngIf="wegLogicData.lagezuschlag == 'Ja'" appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Beschreiben Sie, warum die Lage &uuml;berdurchschnittlich gut ist</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.lagebeschreibung" name="lagebeschreibung" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
      </div>

      <!-- Förderungen -->
      <div *ngIf="wegLogicData.mehrals2VermietbareObj == 'Ja'">
        <div *ngIf="wegLogicData.bewilligungVor08051945 == 'Nein'">
          <div *ngIf="contractOption == 'WEG' && wegLogicData.mietObjType == 'wohnung'" class="custom-box">
            <label>Wurde das Mietobjekt aus dem Wohnhauswiederaufbaufons (WWF) oder Wohnbauf&ouml;rderungsgesetz (WFG)
              1968
              errichtet?</label>
            <br />
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.oeffentlicheFoerderungen" name="oeffentlicheFoerderungen" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let janein of JaNeinOptionen" [value]="janein">
                {{janein}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group><br>
          </div>

          <div *ngIf="contractOption == 'BBL' && ( wegLogicData.mietObjType == 'wohnung' || wegLogicData.mietObjType == 'geschaeftsraum' )" class="custom-box">
            <label>Gab es &ouml;ffentliche F&ouml;rderungen bei urspr&uuml;nglicher Errichtung?</label>
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.oeffentlicheFoerderungen" name="oeffentlicheFoerderungen" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let janein of JaNeinOptionen" [value]="janein">
                {{janein}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group><br>
          </div>
        </div>
        <div *ngIf="contractOption == 'BBL' && wegLogicData.bewilligungVor08051945 == 'Ja' && wegLogicData.zusatzfragebj45 == 'Nein'" class="custom-box">
          <label>Gab es &ouml;ffentliche F&ouml;rderungen bei urspr&uuml;nglicher Errichtung?</label>
          <br />
          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.oeffentlicheFoerderungen" name="oeffentlicheFoerderungen" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of JaNeinOptionen" [value]="janein">
              {{janein}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
         </div>
      </div>

      <h3 class="mt-4 mb-0">Mietdauer</h3>
      <!-- Beginn Mietverhältniss -->
      <div class="radio-left-right mt-2 mb-2">
        <div class="row">
          <div class="col-6">
            <p>Wird der Mietvertrag befristet oder unbefristet abgeschlossen?</p>
          </div>
          <div class="col-6">
            <mat-radio-group required class="example-radio-group"
              [(ngModel)]="wegLogicData.befristung" name="befristung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" [value]="'Ja'">
                Befristet&emsp;
              </mat-radio-button>&emsp;
              <mat-radio-button class="example-radio-button" [value]="'Nein'">
                Unbefristet&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [class.error]="submitted && (!wegLogicData.datumRestoreUebergabe || wegLogicData.datumRestoreUebergabe == '')" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie den Beginn des Mietverhältnisses:</mat-label>
          <input required matInput [matDatepicker]="picker" placeholder=" Datum wählen"
            [(ngModel)]="wegLogicData.datumRestoreUebergabe" name="datumRestoreUebergabe" pattern="^[^\s]+(\s+[^\s]+)*$"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <!-- Mietdauer -->
        <div *ngIf="wegLogicData.mietObjType != 'dienstwohnung' && wegLogicData.befristung == 'Ja'" class="wid100 mat-form-field-div">
          <div *ngIf="!(wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.mietObjType == 'wohnung')" class="wid100">
            <div style="display: flex">
              <mat-form-field appearance="none" class="no-padding no-line half-field" *ngIf="mietdauerjahre" [class.error]="submitted && (!wegLogicData.mietdauerinjahre || wegLogicData.mietdauerinjahre == '')" [class.validation-error]="submitted">
                <mat-label>Mietdauer? (in Jahren)</mat-label>
                <input required matInput [(ngModel)]="wegLogicData.mietdauerinjahre" name="mietdauer" pattern="^[^\s]+(\s+[^\s]+)*$">
              </mat-form-field>
              <mat-form-field appearance="none" class="no-padding no-line  half-field" *ngIf="!mietdauerjahre" [class.error]="submitted && (!wegLogicData.mietdauerinmonate || wegLogicData.mietdauerinmonate == '')" [class.validation-error]="submitted">
                <mat-label>Mietdauer? (in Monaten)</mat-label>
                <input required matInput [(ngModel)]="wegLogicData.mietdauerinmonate" name="mietdauer" pattern="^[^\s]+(\s+[^\s]+)*$">
              </mat-form-field>
              <button *ngIf="mietdauerjahre" mat-raised-button color="primary" matTooltipPosition="below"
                matTooltip="Mit Klicken kann die einzugebene Einheit geändert werden" (click)="togglemietdauerJodM()" class="mr-0 small-height-button" style="margin-top: 20px; margin-left: 10px;">In
                Monaten eingeben</button>
              <button *ngIf="!mietdauerjahre" mat-raised-button color="primary" matTooltipPosition="below"
                matTooltip="Mit Klicken kann die einzugebene Einheit geändert werden" (click)="togglemietdauerJodM()" class="mr-0 small-height-button" style="margin-top: 20px; margin-left: 10px;">In
                Jahren eingeben</button>
            </div>
          </div>

          <div *ngIf="wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.mietObjType == 'wohnung'"  class="wid100">
            <div style="display: flex">
              <mat-form-field appearance="none" class="no-padding no-line half-field" *ngIf="mietdauerjahre" [class.error]="submitted && (!wegLogicData.mietdauerinjahre || wegLogicData.mietdauerinjahre == '')" [class.validation-error]="submitted">
                <mat-label>Mietdauer? (in Jahren)</mat-label>
                <input required pattern="\d{2,}|[3-9]" matTooltipPosition="above" matTooltip="Mindestwert 3 Jahre"
                  matInput [(ngModel)]="wegLogicData.mietdauerinjahre" name="mietdauer">
              </mat-form-field>
              <mat-form-field appearance="none" class="no-padding no-line  half-field" *ngIf="!mietdauerjahre" [class.error]="submitted && (!wegLogicData.mietdauerinmonate || wegLogicData.mietdauerinmonate == '')" [class.validation-error]="submitted">
                <mat-label>Mietdauer? (in Monaten)</mat-label>
                <input required pattern="\d{3,}|[3-9][6-9]|[4-9][0-9]" matTooltipPosition="above"
                  matTooltip="Mindestwert 36 Monate" matInput [(ngModel)]="wegLogicData.mietdauerinmonate"
                  name="mietdauer">
              </mat-form-field>
              <button *ngIf="mietdauerjahre" mat-raised-button color="primary" matTooltipPosition="below"
                matTooltip="Mit Klicken kann die einzugebene Einheit geändert werden" (click)="togglemietdauerJodM()" class="mr-0 small-height-button" style="margin-top: 20px; margin-left: 10px;">In
                Monaten eingeben</button>
              <button *ngIf="!mietdauerjahre" mat-raised-button color="primary" matTooltipPosition="below"
                matTooltip="Mit Klicken kann die einzugebene Einheit geändert werden" (click)="togglemietdauerJodM()" class="mr-0 small-height-button" style="margin-top: 20px; margin-left: 10px;">In
                Jahren eingeben</button>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="wegLogicData.mietObjType != 'dienstwohnung'">
          <div *ngIf="!(wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.mietObjType == 'wohnung')" class="text-right">
          </div>
          <div *ngIf="wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.mietObjType == 'wohnung'">
          </div>
        </div> -->
      </div>

      <!-- Kündigung -->
      <div *ngIf="(wegLogicData.mietObjType == 'wohnung'&& wegLogicData.mehrals2VermietbareObj == 'Nein')
      || (wegLogicData.mietObjType == 'geschaeftsraum'&& wegLogicData.mehrals2VermietbareObj == 'Nein')
      || wegLogicData.mietObjType == 'unternehmenspacht'
      || wegLogicData.mietObjType == 'zweitwohnsitz'
      || wegLogicData.mietObjType == 'parkplatz'
      || wegLogicData.mietObjType == 'sonstigeflaeche'
      || wegLogicData.mietObjType == 'lwpachtvertrag'">
        <h3>vorzeitiges K&uuml;ndigungsrecht</h3>
        <div class="mt-3">
          <div class="radio-left-right">
            <div class="row">
              <div class="col-6">
                <p>Wird ein vorzeitiges K&uuml;ndigungsrecht einger&auml;umt?</p>
              </div>
              <div class="col-6">
                <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="wegLogicData.kuendigungJaNein" name="kuendigungJaNein" [class.validation-error]="submitted">
                  <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                    {{asdf}}&emsp;
                  </mat-radio-button>&emsp;
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div *ngIf="wegLogicData.kuendigungJaNein == 'Ja'">
            <p>Wird der Mieterseite oder beiden Parteien ein vorzeitiges K&uuml;ndigungsrecht einger&auml;umt?</p>
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.kuendigung" name="kuendigung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" [value]="'mieter'">
                Mieterseite&emsp;
              </mat-radio-button>&emsp;
              <mat-radio-button
                *ngIf="!(wegLogicData.mietObjType=='geschaeftsraum' && wegLogicData.mehrals2VermietbareObj == 'Nein')"
                class="example-radio-button" [value]="'beide'">
                beide Vertragsparteien&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group><br>
            <br>
            <p>Ab wann soll dieses K&uuml;ndigungsrecht greifen?</p>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>Nach Ablauf von ... Monaten</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.kuendigunginmonaten" name="kuendigunginmonaten" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <p>Wie lange ist die K&uuml;ndigungsfrist?</p>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>K&uuml;ndigungsfrist in Monaten</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.kuendigungsfrist" name="kuendigungsfrsit" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
          </div>
        </div>
      </div>

      <h3>Mietzins</h3>
      <!-- Umsatzsteuerverpflichtung -->
      <div class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>Ist die Vermieterseite umsatzsteuerpflichtig?</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.umsatzsteuerverpflichtung" (change)="mietzinschange($event, 'USt')"
              name="umsatzsteuerverpflichtung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen" [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>
      <!-- Hauptzins -->
      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line full" [class.error]="submitted && (!wegLogicData.HauptmietzinsWohnungEUR || wegLogicData.HauptmietzinsWohnungEUR == '')" [class.validation-error]="submitted">
          <mat-label *ngIf="wegLogicData.mietObjType == 'dienstwohnung' || wegLogicData.mietObjType =='wohnung' || (!(wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag') &&
          wegLogicData.mietObjType != 'geschaeftsraum' &&
          wegLogicData.mietObjType != 'sonstigeflaeche' &&
          wegLogicData.mietObjType != 'zweitwohnsitz' &&
          wegLogicData.mietObjType != 'parkplatz')">
            Welcher Hauptzins für die Wohnung (ohne Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

          <mat-label
            *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
            Welcher Hauptzins für das Pachtobjekt (ohne
            Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

          <mat-label *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Welcher Hauptzins für den Gesch&auml;ftsraum
            (ohne Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

          <mat-label *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Welcher Hauptzins für die sonstige
            Fl&auml;che (ohne
            Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

          <mat-label *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Welcher Hauptzins für den Zweitwohnsitz
            (ohne Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

          <mat-label *ngIf="wegLogicData.mietObjType == 'parkplatz'">Welcher Hauptzins für den Parkplatz oder Garage
            (ohne Betriebskosten) wurde vereinbart (in EUR)?</mat-label>

            <input aa required matInput [ngModel]="wegLogicData.HauptmietzinsWohnungEUR | looseCurrency:'EUR'" name="HauptmietzinsWohnungEUR" [ngModelOptions]="{updateOn:'blur'}" id=kaufpreis #kaufpreis (ngModelChange)="wegLogicData.HauptmietzinsWohnungEUR=$event" (blur)="mietzinschange($event, 'wohn')" (input)="mietzinschange($event, 'wohn')" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
      </div>

      <!-- Parkzins -->
      <div *ngIf="contractData.contactData.contractOption == 'WEG'" class="half-field">
        <mat-form-field matTooltipPosition="above" matTooltip="wenn kein Parkplatz, dann 0 eingeben" appearance="none"
          class="no-padding no-line full">
          <mat-label>Welcher Hauptzins für den Parkplatz wurde vereinbart (in EUR)?</mat-label>
          <input matInput (blur)="mietzinschange($event, 'park')"
            [ngModel]="wegLogicData.HauptmietzinsParkplaetzeEUR | looseCurrency:'EUR'"
            name="HauptmietzinsParkplaetzeEUR" [ngModelOptions]="{updateOn:'blur'}"
            (ngModelChange)="wegLogicData.HauptmietzinsParkplaetzeEUR=$event">
        </mat-form-field>
      </div>

      <!-- Tabelle Mietzins -->
      <div class="custom-box" style="padding:1%; margin-top: 20px !important">
        <p><b>Berechnung des Mietzinses</b></p>
        <div *ngIf="contractData.contactData.contractOption === 'WEG'">
          <div *ngIf="wegLogicData.umsatzsteuerverpflichtung != 'Ja'">
            <table style="width: 45%; margin: auto;">
              <tr>
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Hauptzins Pachtobjekt Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Hauptzins sonstige Fl&auml;che Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Hauptzins Zweitwohnsitz Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Hauptzins Parkplatz oder Garage Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'dienstwohnung' || wegLogicData.mietObjType == 'wohnung'">
                  Hauptzins Wohnung Netto</td>
                <td>{{ wegLogicData.HauptmietzinsWohnungEUR }}</td>
              </tr>
              <tr *ngIf="!wegLogicData.parkplatz">
                <td>Hauptzins Parkplatz&#47;Parkpl&auml;tze Netto</td>
                <td>{{ wegLogicData.HauptmietzinsParkplaetzeEUR }}</td>
              </tr>
              <tr>
                <td>gesamter Hauptmietzins</td>
                <td>{{ wegLogicData.GesamtHauptmietzinsEURmitUSt }}</td>
              </tr>
            </table>
          </div>

          <div *ngIf="wegLogicData.umsatzsteuerverpflichtung == 'Ja'">
            <table style="width: 45%; margin: auto;">
              <tr>
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Hauptzins Pachtobjekt Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Hauptzins sonstige Fl&auml;che Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Hauptzins Zweitwohnsitz Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Hauptzins Parkplatz oder Garage Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'dienstwohnung' || wegLogicData.mietObjType == 'wohnung'">
                  Hauptzins Wohnung Netto</td>
                <td>{{ wegLogicData.HauptmietzinsWohnungEUR }}</td>
              </tr>
              <tr>
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Umsatzsteuer Gesch&auml;ftsraum
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Umsatzsteuer Zweitwohnsitz
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Umsatzsteuer Parkplatz oder Garage
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Umsatzsteuer sonstige Fl&auml;che
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Umsatzsteuer Pachtobjekt {{this.UStSatzMiet
                  * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'dienstwohnung' || wegLogicData.mietObjType == 'wohnung'">
                  Umsatzsteuer Wohnung {{this.UStSatzMiet * 100}}&#37;</td>
                <td>{{ wegLogicData.UStWohnungEUR }}</td>
              </tr>
              <tr *ngIf="!wegLogicData.parkplatz">
                <td>Hauptzins Parkplatz&#47;Parkpl&auml;tze Netto</td>
                <td>{{ wegLogicData.HauptmietzinsParkplaetzeEUR }}</td>
              </tr>
              <tr *ngIf="!wegLogicData.parkplatz">
                <td>Umsatzsteuer Parkplatz&#47;Parkpl&auml;tze 20&#37;</td>
                <td>{{ wegLogicData.UStParkplaetzeEUR }}</td>
              </tr>
              <tr>
                <td>gesamter Hauptmietzins</td>
                <td>{{ wegLogicData.GesamtHauptmietzinsEURmitUSt }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="contractData.contactData.contractOption === 'BBL'">
          <div *ngIf="wegLogicData.umsatzsteuerverpflichtung != 'Ja'">
            <table style="width: 45%; margin: auto;">
              <tr>
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Hauptzins Zweitwohnsitz Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Hauptzins sonstige Fl&auml;che Netto</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Hauptzins Pachtobjekt Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Hauptzins Parkplatz oder Garage Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'wohnung' || wegLogicData.mietObjType == 'dienstwohnung'">
                  Hauptzins Wohnung Netto</td>
                <td>{{ wegLogicData.HauptmietzinsWohnungEUR }}</td>
              </tr>
              <tr>
                <td>gesamter Hauptmietzins</td>
                <td>{{ wegLogicData.GesamtHauptmietzinsEURmitUSt }}</td>
              </tr>
            </table>
          </div>

          <div *ngIf="wegLogicData.umsatzsteuerverpflichtung == 'Ja'">
            <table style="width: 45%; margin: auto;">
              <tr>
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Hauptzins Gesch&auml;ftsraum Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Hauptzins Parkplatz oder Garage Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Hauptzins Zweitwohnsitz Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Hauptzins sonstige Fl&auml;che Netto</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Hauptzins Pachtobjekt Netto</td>
                <td *ngIf="wegLogicData.mietObjType == 'wohnung' || wegLogicData.mietObjType == 'dienstwohnung'">
                  Hauptzins Wohnung Netto</td>
                <td>{{ wegLogicData.HauptmietzinsWohnungEUR }}</td>
              </tr>
              <tr *ngIf="!wegLogicData.parkplatz">
                <td *ngIf="wegLogicData.mietObjType == 'geschaeftsraum'">Umsatzsteuer Gesch&auml;ftsraum
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'parkplatz'">Umsatzsteuer Parkplatz oder Garage
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'zweitwohnsitz'">Umsatzsteuer Zweitwohnsitz
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche'">Umsatzsteuer sonstige Fl&auml;che
                  {{this.UStSatzMiet * 100}}&#37;</td>
                <td
                  *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'lwpachtvertrag'">
                  Umsatzsteuer Pachtobjekt {{this.UStSatzMiet
                  * 100}}&#37;</td>
                <td *ngIf="wegLogicData.mietObjType == 'wohnung' || wegLogicData.mietObjType == 'dienstwohnung'">
                  Umsatzsteuer Wohnung {{this.UStSatzMiet * 100}}&#37;</td>
                <td>{{ wegLogicData.UStWohnungEUR }}</td>
              </tr>

              <tr>
                <td>gesamter Hauptmietzins</td>
                <td>{{ wegLogicData.GesamtHauptmietzinsEURmitUSt }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- <br>
      mietObjType - {{ wegLogicData.mietObjType }}
      <br>
      mehrals2VermietbareObj - {{ wegLogicData.mehrals2VermietbareObj }}
      <br>
      bewilligungVor08051945 - {{ wegLogicData.bewilligungVor08051945 }}
      <br>
      oeffentlicheFoerderungen - {{ wegLogicData.oeffentlicheFoerderungen }}
      <br>
      <br>
      <br> -->

      <div class="mt-5" *ngIf="(wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.bewilligungVor08051945 == 'Ja' && wegLogicData.denkmalschutz == 'Nein'); else indexjaehrlich">
        <div class="radio-left-right">
          <div class="row">
            <div class="col-6">
              <p>Soll die Indexierung des Mietzinses jährlich oder bei Überschreitung/Unterschreitung einer Prozentzahl erfolgen?</p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.indexjaehrlich" name="indexjaehrlich" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" [value]="'Ja'">
                  jährlich &emsp;
                </mat-radio-button>&emsp;
                <mat-radio-button class="example-radio-button" [value]="'Nein'">
                  prozentual &emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="half-field" *ngIf="wegLogicData.indexjaehrlich == 'Nein'">
          <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
            <mat-label>Geben sie einen Prozentsatz zwischen 1 und 5 ein</mat-label>
            <input type="number" required matInput [(ngModel)]="wegLogicData.indexwert"
              name="indexwert" min="1" max="5" required>
          </mat-form-field>
        </div>
      </div>
      <ng-template #indexjaehrlich>

        <div [class.hide]='wegLogicData.mietObjType == "wohnung" &&
         ((wegLogicData.mehrals2VermietbareObj == "Ja" && wegLogicData.bewilligungVor08051945 == "Ja") ||
         (wegLogicData.mehrals2VermietbareObj == "Ja" && wegLogicData.bewilligungVor08051945 == "Nein" && wegLogicData.oeffentlicheFoerderungen == "Ja"))'>
         <div>
           <div class="mt-5">
             <div class="radio-left-right">
               <div class="row">
                 <div class="col-6">
                   <p>Soll die Indexierung des Mietzinses jährlich oder bei Überschreitung/Unterschreitung einer Prozentzahl erfolgen?</p>
                 </div>
                 <div class="col-6">
                   <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.indexjaehrlich" name="indexjaehrlich" [class.validation-error]="submitted">
                     <mat-radio-button class="example-radio-button" [value]="'Ja'">
                       jährlich &emsp;
                     </mat-radio-button>&emsp;
                     <mat-radio-button class="example-radio-button" [value]="'Nein'">
                       prozentual &emsp;
                     </mat-radio-button>&emsp;
                   </mat-radio-group>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="half-field" *ngIf="wegLogicData.indexjaehrlich == 'Nein'">
           <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
             <mat-label>Geben sie einen Prozentsatz zwischen 1 und 5 ein</mat-label>
             <input type="number" required matInput [(ngModel)]="wegLogicData.indexwert"
               name="indexwert" min="1" max="5" required>
           </mat-form-field>
         </div>
        </div>
        <!-- <div class="mt-5" *ngIf='wegLogicData.mietObjType != "wohnung" && wegLogicData.bewilligungVor08051945 == "Nein"'>
          <div class="mt-5">
            <div class="radio-left-right">
              <div class="row">
                <div class="col-6">
                  <p>Soll die Indexierung des Mietzinses jährlich oder bei Überschreitung/Unterschreitung einer Prozentzahl erfolgen?</p>
                </div>
                <div class="col-6">
                  <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.indexjaehrlich" name="indexjaehrlich" [class.validation-error]="submitted">
                    <mat-radio-button class="example-radio-button" [value]="'Ja'">
                      jährlich &emsp;
                    </mat-radio-button>&emsp;
                    <mat-radio-button class="example-radio-button" [value]="'Nein'">
                      prozentual &emsp;
                    </mat-radio-button>&emsp;
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="mt-5" *ngIf='wegLogicData.mietObjType != "wohnung" && wegLogicData.oeffentlicheFoerderungen == "Nein"'>
          <div class="mt-5">
            <div class="radio-left-right">
              <div class="row">
                <div class="col-6">
                  <p>Soll die Indexierung des Mietzinses jährlich oder bei Überschreitung/Unterschreitung einer Prozentzahl erfolgen?</p>
                </div>
                <div class="col-6">
                  <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.indexjaehrlich" name="indexjaehrlich" [class.validation-error]="submitted">
                    <mat-radio-button class="example-radio-button" [value]="'Ja'">
                      jährlich &emsp;
                    </mat-radio-button>&emsp;
                    <mat-radio-button class="example-radio-button" [value]="'Nein'">
                      prozentual &emsp;
                    </mat-radio-button>&emsp;
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </ng-template>
      <!-- <div class="mt-5" *ngIf='wegLogicData.mietObjType == "wohnung" && ((wegLogicData.mehrals2VermietbareObj == "Ja" &&
      wegLogicData.bewilligungVor08051945 == "Ja") || (wegLogicData.mehrals2VermietbareObj == "Ja"
      && wegLogicData.bewilligungVor08051945 == "Nein" && wegLogicData.oeffentlicheFoerderungen == "Ja")); else indexjaehrlich'>
      </div> -->

      <!-- Betriebskosten -->
      <div *ngIf="wegLogicData.mietObjType != 'lwpachtvertrag'">
        <h3>Betriebskosten</h3>
        <div class="radio-left-right">
          <div class="row">
            <div class="col-6">
              <p>Wählen Sie die Art der Betriebskosten:</p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.betriebskostenType" name="betriebskostenType" *ngIf="wegLogicData.mietObjType != 'sonstigeflaeche' && wegLogicData.mietObjType != 'parkplatz'" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of mietBetriebskostenOptionen" [value]="asdf.value">
                  {{asdf.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.betriebskostenType" name="betriebskostenType" *ngIf="wegLogicData.mietObjType == 'sonstigeflaeche' || wegLogicData.mietObjType == 'parkplatz'" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of mietBetriebskostenOptionenSpezial" [value]="asdf.value">
                  {{asdf.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line" *ngIf="wegLogicData.betriebskostenType == 'akonto'" [class.error]="submitted && (!wegLogicData.monatlicheBetriebskosten || wegLogicData.monatlicheBetriebskosten == '')" [class.validation-error]="submitted">
            <mat-label>In welcher Höhe sind die monatlichen Betriebskostenakonti zu bezahlen?</mat-label>
            <input required matInput [ngModel]="wegLogicData.monatlicheBetriebskosten | looseCurrency:'EUR'"
              name="monatlicheBetriebskosten" [ngModelOptions]="{updateOn:'blur'}"
              (ngModelChange)="wegLogicData.monatlicheBetriebskosten=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
          <mat-form-field appearance="none" class="no-padding no-line" *ngIf="wegLogicData.betriebskostenType == 'fixbetrag'" [class.error]="submitted && (!wegLogicData.monatlicheBetriebskosten || wegLogicData.monatlicheBetriebskosten == '')" [class.validation-error]="submitted">
            <mat-label>Wie hoch ist der monatliche Fixbetrag für Betriebskosten?</mat-label>
            <input required matInput [ngModel]="wegLogicData.monatlicheBetriebskosten | looseCurrency:'EUR'"
              name="monatlicheBetriebskosten" [ngModelOptions]="{updateOn:'blur'}"
              (ngModelChange)="wegLogicData.monatlicheBetriebskosten=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
        </div>
        <!-- Betriebskosten -->
        <!-- <div *ngIf="!(wegLogicData.mietObjType == 'lwpachtvertrag' && wegLogicData.lwbebaut == 'Nein')">
          <div
            *ngIf="(wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.bewilligungVor08051945 == 'Ja')
              || (wegLogicData.mehrals2VermietbareObj == 'Ja' && wegLogicData.bewilligungVor08051945 == 'Nein' && wegLogicData.oeffentlicheFoerderungen == 'Ja'); else normbetriebsK">
            <mat-form-field appearance="none" class="no-padding no-line">
              <mat-label>In welcher Höhe sind die monatlichen Betriebskostenakonti zu bezahlen?</mat-label>
              <input required matInput [ngModel]="wegLogicData.monatlicheBetriebskosten | looseCurrency:'EUR'"
                name="monatlicheBetriebskosten" [ngModelOptions]="{updateOn:'blur'}"
                (ngModelChange)="wegLogicData.monatlicheBetriebskosten=$event">
            </mat-form-field>
            <br>
          </div>
          <ng-template #normbetriebsK>
            <div class="custom-box">
              <p>Wählen Sie die Art der Betriebskosten:</p>
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.betriebskostenType" name="betriebskostenType">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of mietBetriebskostenOptionen"
                  [value]="asdf.value">
                  {{asdf.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
              <br>

              <div *ngIf="wegLogicData.betriebskostenType == 'akonto'">
                <mat-form-field appearance="none" class="no-padding no-line">
                  <mat-label>In welcher Höhe sind die monatlichen Betriebskostenakonti zu bezahlen?</mat-label>
                  <input required matInput [ngModel]="wegLogicData.monatlicheBetriebskosten | looseCurrency:'EUR'"
                    name="monatlicheBetriebskosten" [ngModelOptions]="{updateOn:'blur'}"
                    (ngModelChange)="wegLogicData.monatlicheBetriebskosten=$event">
                </mat-form-field>
                <br>
              </div>

              <div *ngIf="wegLogicData.betriebskostenType == 'fixbetrag'">
                <mat-form-field appearance="none" class="no-padding no-line">
                  <mat-label>Wie hoch ist der monatliche Fixbetrag für Betriebskosten?</mat-label>
                  <input required matInput [ngModel]="wegLogicData.monatlicheBetriebskosten | looseCurrency:'EUR'"
                    name="monatlicheBetriebskosten" [ngModelOptions]="{updateOn:'blur'}"
                    (ngModelChange)="wegLogicData.monatlicheBetriebskosten=$event">
                </mat-form-field>
                <br>
              </div>
            </div>
          </ng-template>
        </div> -->

      </div>
      <div class="col-md-12">
        <div class="yellow-line-2 mb-4 mt-4">
        </div>
      </div>
      <h3>Kaution</h3>
      <!-- Kaution -->
      <div class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>Art und H&ouml;he der Kaution:</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.kautionType" name="kautionType" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of kautionOptionen" [value]="asdf.value">
                {{asdf.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>

        <div *ngIf="wegLogicData.kautionType != 'keine' && wegLogicData.kautionType != 'behoerdengarantie' &&  wegLogicData.kautionType" class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
            <mat-label>Die H&ouml;he der Kaution bel&auml;uft sich auf?</mat-label>
            <input required matInput [ngModel]="wegLogicData.kaution | looseCurrency:'EUR'" name="kaution"
              [ngModelOptions]="{ updateOn:'blur' }" (ngModelChange)="wegLogicData.kaution=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
          <br>
        </div>

      </div>
      <div class="col-md-12">
        <div class="yellow-line-2 mb-4 mt-4">
        </div>
      </div>
      <h3 style="margin-bottom: 0px !important">Sonstiges</h3>
      <!-- verpflichtungen mieter -->
      <div *ngIf="wegLogicData.mietObjType != 'parkplatz'" class="half-field">
        <!-- *ngIf="!(wegLogicData.mehrals2VermietbareObj == 'Ja'
        && (wegLogicData.bewilligungVor08051945 == 'Ja'
        || wegLogicData.bewilligungVor08051945 == 'Nein' && wegLogicData.oeffentlicheFoerderungen == 'Ja'))" -->

        <div *ngIf="wegLogicData.mehrals2VermietbareObj == 'Ja' && ( wegLogicData.bewilligungVor08051945 == 'Ja' || wegLogicData.bewilligungVor08051945 == 'Nein' && wegLogicData.oeffentlicheFoerderungen == 'Ja'); else verpflichtungen">
          <mat-form-field appearance="none" class="no-padding no-line">
            <mat-label>Umfang der Verpflichtungen der Mieterseite</mat-label>
            <mat-select multiple [(ngModel)]="wegLogicData.verpflichtungenMieter" name="verpflichtungenMieter">
              <mat-option *ngFor="let asdf of verpflichtungMieterOptionenSpezi" [value]="asdf.value">
                {{ asdf.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-template #verpflichtungen>
          <div>
            <mat-form-field appearance="none" class="no-padding no-line">
              <mat-label>Umfang der Verpflichtungen der Mieterseite</mat-label>
              <mat-select multiple [(ngModel)]="wegLogicData.verpflichtungenMieter" name="verpflichtungenMieter">
                <mat-option *ngFor="let asdf of verpflichtungMieterOptionen" [value]="asdf.value">
                  {{ asdf.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-template>
      </div>

      <div *ngIf="wegLogicData.mietObjType =='parkplatz' " class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line">
          <mat-label>Umfang der Verpflichtungen der Mieterseite</mat-label>
          <mat-select multiple [(ngModel)]="wegLogicData.verpflichtungenMieter" name="verpflichtungenMieter">
            <mat-option *ngFor="let asdf of verpflichtungMieterOptionenPark" [value]="asdf.value">
              {{asdf.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- werKostenVertragserrichtung -->
      <div class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>Wer bezahlt Kosten der Mietvertragserrichtung?</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.werKostenVertragserrichtung" name="werKostenVertragserrichtung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of MieterVermieterOptionen"
                [value]="asdf.value">
                {{asdf.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br>
      </div>

      <!-- untermietverbot -->
      <div class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>Untermietverbot</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.untermietverbot" name="untermietverbot" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen"
                [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br>
      </div>

      <!-- untermietverbot -->
      <div class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>Benützungsregelungen übergeben</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.benuetzungsregelung" name="benuetzungsregelung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen"
                [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br>
      </div>

      <!-- untermietverbot -->
      <div class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>Hausordnung übergeben</p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.hausordnung" name="hausordnung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of JaNeinOptionen"
                [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br>
      </div>

      <div *ngIf="contractOption == 'BBL'" class="custom-top-line">
        <div class="radio-left-right mt-3">
          <div class="row">
            <div class="col-6">
              <p>Bildet die gesamte Liegenschaft den Mietgegenstand oder nur einzelne Grundst&uuml;cke oder
                Teilfl&auml;chen?</p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.mietuebertragungsumfang" name="mietuebertragungsumfang" [class.validation-error]="submitted" style="text-align: left !important;">
                <mat-radio-button class="example-radio-button" *ngFor="let uebertragung of uebertragsumfangArten"
                  [value]="uebertragung">
                  {{uebertragung}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br />
            </div>
          </div>
        </div>
        <div *ngIf="wegLogicData.mietuebertragungsumfang === 'Teilfläche lt. Lageplan (ist dem Mietvertrag beizulegen)'">
          <label>Bitte geben Sie nur die Grundstücke ein, die vermietet werden!</label>
        </div>
      </div>
    </div>
    <!-- Miet Section End -->

    <!-- Not Miet Section -->
    <div *ngIf="!this.miet && this.contractData.primeType !='kfz'">
      <div *ngIf="this.schenkung" class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wurde eine Ausgleichszahlung vereinbart?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.Gegenleistung" name="ausgleichszahlung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let Gegenleistung  of GegenleistungOptionen"
                [value]="Gegenleistung">
                {{Gegenleistung}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="this.schenkung && wegLogicData.Gegenleistung === 'Ja'" class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
            <mat-label>Die Ausgleichszahlung beläuft sich auf</mat-label>
            <input required matInput [ngModel]="wegLogicData.kaufpreis | looseCurrency:'EUR'" name="kaufpreis"
              id="kaufpreis" #kaufpreis [ngModelOptions]="{updateOn:'blur'}"
              (ngModelChange)="wegLogicData.kaufpreis=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2"></div>
        </div>
      </div>
      <div *ngIf="!this.schenkung" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Welcher Kaufpreis wurde vereinbart (in EUR)?</mat-label>
          <input required matInput [ngModel]="wegLogicData.kaufpreis | looseCurrency:'EUR'" name="kaufpreis"
            id="kaufpreis" #kaufpreis [ngModelOptions]="{updateOn:'blur'}"
            (ngModelChange)="wegLogicData.kaufpreis=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
      </div>

      <div *ngIf="this.primeType === 'kaufanbot'" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie das Enddatum der Gültigkeit des Anbots</mat-label>
          <input required matInput [matDatepicker]="picker" placeholder=" Datum wählen"
            [(ngModel)]="wegLogicData.datumRestoreBindung" name="bindungDatum">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div *ngIf="!this.schenkung" style="display: flex;" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie das Datum der Übergabe an den Käufer</mat-label>
          <input required matInput [matDatepicker]="picker" placeholder=" Datum wählen"
            [(ngModel)]="wegLogicData.datumRestoreUebergabe" matTooltipPosition="above"
            matTooltip="Die Übergabe kann frühestens mit Einlagen des Kaufpreises und der Steuern und Gebühren auf dem Treuhandkonto erfolgen. Erst dann ist die Verkäuferseite abgesichert. Es kann jedoch ein Übergabetermin festgehalten werden, an welchem die Übergabe stattfinden soll, wobei der Hinweis im Vertrag erfolgt, dass der früheste Termin bei Eingang auf das Treuhandkonto erfolgt."
            name="datumRestoreUebergabe" pattern="^[^\s]+(\s+[^\s]+)*$">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="!this.schenkung" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie den Umfang der Gewährleistung</mat-label>
            <mat-select required [(ngModel)]="wegLogicData.umfangGewaehrleistung" name="umfangGewaehrleistung3">
            <mat-option *ngFor="let  umfangGewaehrleistung of umfangGewaehrleistungOptionen"
              [value]="umfangGewaehrleistung">
              {{umfangGewaehrleistung}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!this.schenkung" class="half-field">
        <div *ngIf="this.contractData.masterData.sellersPrivate.length == 0" style="display: flex;">
          <mat-form-field appearance="none" class="no-padding no-line custom-icon-field" [class.validation-error]="submitted">
            <mat-label>Liegt eine Befreiung zur Immobilienertragssteuer vor?</mat-label>
            <mat-select [(ngModel)]="wegLogicData.ausnahmeIEST" name="ausnahmeIEST" required>
              <mat-option *ngFor="let ausnahmeIEST of ausnahmeIESTOptionen" [value]="ausnahmeIEST.value">
                {{ausnahmeIEST.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="custom-icon-div ">
            <fa-icon (click)="openImmoSteuerDlg()" class="custom-icon" [icon]="faUniversity"></fa-icon>
          </div>
        </div>
        <div *ngIf="this.contractData.masterData.sellersPrivate.length != 0" style="display: flex;">
          <mat-form-field appearance="none" class="no-padding no-line custom-icon-field" [class.validation-error]="submitted">
            <mat-label>Liegt eine Befreiung zur Immobilienertragssteuer vor?</mat-label>
            <mat-select [(ngModel)]="wegLogicData.ausnahmeIEST" name="ausnahmeIEST" required>
              <mat-option *ngFor="let ausnahmeIEST of ausnahmeIESTOptionenPrivat" [value]="ausnahmeIEST.value">
                {{ausnahmeIEST.viewValue}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <div class="custom-icon-div">
            <fa-icon (click)="openImmoSteuerDlg()" class="custom-icon" [icon]="faUniversity"></fa-icon>
          </div>
        </div>
      </div>

      <div *ngIf="!this.schenkung" class="radio-left-right mt-3">
        <div class="row mb-4 mt-3" *ngIf="(contractData.contactData.contractOption === 'WEG' || contractData.contactData.contractOption === 'BBL') && !this.schenkung" style="margin-top: 15px !important;">
          <div class="col-6">
            <p>
              <label>Wird Inventar mitverkauft?
                &nbsp;
                <fa-icon style="vertical-align: top;" [icon]="faInfoCircle" matTooltipPosition="above"
                  matTooltip="Inventar sind bewegliche Sachen (z.B. Wohnungseinrichtung), die mit dem Gebäude nicht fest verbunden sind oder ohne Zerstörung und wirtschaftlich sinnvoll vom Gebäude losgelöst werden können. Der auf das übernommene Inventar entfallende Anteil der Gegenleistung fällt nicht in die Bemessungsgrundlage für die Grunderwerbsteuer und Eintragungsgebühr.">
                </fa-icon>
              </label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wegLogicData.inventar" name="inventar" required [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let inventar of inventarOptionen" [value]="inventar">
                {{inventar}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="wegLogicData.inventar == 'Ja'" class="half-field mt-2">
          <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
            <mat-label>Wie hoch ist der Wert des Inventars (in EUR)?</mat-label>
            <input required matInput [ngModel]="wegLogicData.inventarWert | looseCurrency:'EUR'" name="inventarWert"
              [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="wegLogicData.inventarWert=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2"></div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wie erfolgt die Übertragung?</label>
              <fa-icon (click)="openGeldlastenDlg()" [icon]="faUniversity"></fa-icon>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.uebertragung" name="uebetragung" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let uebertragung of uebertragungArten"
                [value]="uebertragung">
                {{uebertragung}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2"></div>
        </div>

        <div *ngIf="wegLogicData.uebertragung == 'Verbindlichkeiten werden mitübernommen' && !this.LSBOOL[1]">
          <div *ngFor="let clnr of wegLogicData.uebertragungCLfdNr; let i = index; trackBy:trackByFn" class="half-field">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>Bitte geben Sie die C-LNR Nr. ein</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.uebertragungCLfdNr[i]"
                name="uerbertragungCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="removeuebertragungCLfdNr(i)">
              Löschen
            </button>
          </div>
          <br>
          <button mat-raised-button color="primary" (click)="adduebertragungCLfdNr()">
            C-LNR hinzufügen
          </button>
        </div>

        <div *ngIf="wegLogicData.uebertragung == 'Verbindlichkeiten werden mitübernommen' && this.LSBOOL[1]">
          <label>Bitte geben Sie die C-LNR Nr. und die jeweilige EZ ein: </label>
          <div *ngFor="let clnr of wegLogicData.uebertragungCLfdNr; let i = index; trackBy:trackByFn">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>C-LNR</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.uebertragungCLfdNr[i]"
                name="uerbertragungCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <label> in EZ:</label>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>EZ</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.uebertragungEzClnr[i]"
                name="uerbertragungEzCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="removeuebertragungEzClnr(i)">
              Löschen
            </button>
            <br>
          </div>
          <button mat-raised-button color="primary" (click)="adduebertragungEzClnr()">
            C-LNR hinzufügen
          </button>
        </div>
      </div>

      <!-- Lasten Löschen -->
      <div class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>
              <label *ngIf="!schenkung">Sollen sonstige Lasten (nicht Geldlasten) gelöscht werden?</label>
              <label *ngIf="schenkung">Sollen sonstige Lasten gelöscht werden?</label>
              <fa-icon (click)="openLoeschenSonstigerLasten()" [icon]="faUniversity"></fa-icon>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.lastenLoeschen" name="lastenLoeschen" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let lastenLoeschen of lastenLoeschenOptionen"
                [value]="lastenLoeschen">
                {{lastenLoeschen}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2"></div>
        </div>

        <div *ngIf="wegLogicData.lastenLoeschen == 'Ja' && !this.LSBOOL[1]" class="mt-3">
          <label>Bitte geben Sie die die C-LNR ein: </label>
          <div *ngFor="let clnr of wegLogicData.lastenLoeschenCLfdNr; let i = index; trackBy:trackByFn">
            <div class="half-field">
              <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
                <mat-label>C-LNR</mat-label>
                <input required matInput [(ngModel)]="wegLogicData.lastenLoeschenCLfdNr[i]"
                  name="lastenLoeschenCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
              </mat-form-field>
              <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
                <mat-label>Bitte geben Sie die Art der Löschung an:</mat-label>
                <mat-select [(ngModel)]="wegLogicData.lastenloeschenType[i]" required name="lastenloeschenType{{i}}">
                  <mat-option *ngFor="let option of lastenloeschenTypeOptionen" [value]="option.value">
                    {{option.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
                <mat-label> Bitte geben Sie die Last laut Grundbucheintrag ein:</mat-label>
                <input required matInput [(ngModel)]="wegLogicData.lastenloeschenGrundbuch[i]"
                  name="lastenLoeschenGrundbuch{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
              </mat-form-field>
              <div *ngIf="wegLogicData.lastenloeschenType[i] === 'einverleibung'">
                <label>Wer verzichtet auf das Recht?</label>
                <br>
                <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted" *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
                  <mat-label>Privatperson</mat-label>
                  <mat-select [(ngModel)]="wegLogicData.LastenLoeschenBeteiligte[i].personArray" multiple
                    name="LastenLoeschenperson{{i}}">
                    <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList"
                      [value]="rightOwner">
                      {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
                  </mat-select>
                </mat-form-field>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted"
                  *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
                  <mat-label>Firma</mat-label>
                  <mat-select [(ngModel)]="wegLogicData.LastenLoeschenBeteiligte[i].companyArray" multiple
                    name="LastenLoeschencompany{{i}}">
                    <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList"
                      [value]="rightOwner">
                      {{rightOwner.companyName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button mat-raised-button color="primary" (click)="removelastenLoeschenCLfdNr(i)">
                Löschen
              </button>
              <br>
            </div>
            <div class="radio-left-right" *ngIf="wegLogicData.lastenloeschenType[i] === 'einverleibung'">
              <div class="row">
                <div class="col-6">
                  <p>
                    <label>Sind alle Verzichtende bereits Vertragspartei?</label>
                  </p>
                </div>
                <div class="col-6">
                  <!-- {{ wegLogicData.frageLastenLoeschenDritte2 | json }} -->
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="wegLogicData.frageLastenLoeschenDritte2[i]" [name]="'frageLastenLoeschenDritte2' + i" required [class.validation-error]="submitted">
                    <mat-radio-button class="example-radio-button" *ngFor="let frageRechteDritter of lastenLoeschenOptionen"
                      [value]="frageRechteDritter">
                      {{frageRechteDritter}}&emsp;
                    </mat-radio-button>
                    &emsp;
                  </mat-radio-group>
                  <br>
                </div>
              </div>
              <br />
            </div>
            <div *ngIf="wegLogicData.frageLastenLoeschenDritte2 && wegLogicData.frageLastenLoeschenDritte2[i] && wegLogicData.frageLastenLoeschenDritte2[i] == 'Nein'">
              <h3>Verzichtende Partei hinzufügen:</h3>
              <div>
                <br>
                <label>Geben Sie die Daten des Rechteinhabers ein: </label>
                <div *ngFor="let buyer of LastenLoeschenPrivateFormArray.controls; let i = index">
                  <h4 class="title-with-icon">Rechteinhaber Privatperson {{i + 1}}
                    <fa-icon [icon]="faTimes" class="icon" (click)="removeLastenLoeschenPrivate(i)"></fa-icon>
                  </h4>
                  <app-person-data-form [parent]="buyer" [submitted]="submitted"></app-person-data-form>
                </div>
                <div *ngFor="let buyer of LastenLoeschenCompanyFormArray.controls; let i = index">
                  <h4 class="title-with-icon">Rechteinhaber Firma {{i + 1}}
                    <fa-icon [icon]="faTimes" class="icon" (click)="removeLastenLoeschenCompany(i)"></fa-icon>
                  </h4>
                  <app-company-data-form [parent]="buyer" [submitted]="submitted"></app-company-data-form>
                </div>
                <div>
                  <button mat-raised-button color="primary" (click)="addLastenLoeschenOwnerComplex('person')">
                    Privatperson hinzufügen
                  </button>
                  <button mat-raised-button color="primary" (click)="addLastenLoeschenOwnerComplex('company')">
                    Firma hinzufügen
                  </button>
                  <br>
                  <div>
                    <button mat-raised-button class="save-button" (click)="refreshLastenLoeschenOwners(true)">
                      Rechteinhaber speichern&nbsp;<fa-icon style="color: inherit; background-color: inherit;"
                        [icon]="faSave"></fa-icon>
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <button mat-raised-button color="primary" (click)="addlastenLoeschenCLfdNr()">
            C-LNR hinzufügen
          </button>
        </div>

        <div *ngIf="wegLogicData.lastenLoeschen == 'Ja' && this.LSBOOL[1]">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Ist der Verzichtende Vertragspartei</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.frageLastenLoeschenDritte" name="frageLastenLoeschenDritte" required [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let frageRechteDritter of lastenLoeschenOptionen"
                  [value]="frageRechteDritter">
                  {{frageRechteDritter}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br>
            </div>
          </div>

          <div *ngIf="wegLogicData.frageLastenLoeschenDritte == 'Nein'">
            <h3>Verzichtende Partei hinzufügen:</h3>
            <div>
              <br>
              <label>Geben Sie die Daten des Rechteinhabers ein: </label>
              <div *ngFor="let buyer of LastenLoeschenPrivateFormArray.controls; let i = index">
                <h4 class="title-with-icon">Rechteinhaber Privatperson {{i + 1}}
                  <fa-icon [icon]="faTimes" class="icon" (click)="removeLastenLoeschenPrivate(i)"></fa-icon>
                </h4>
                <app-person-data-form [parent]="buyer" [submitted]="submitted"></app-person-data-form>
              </div>
              <div *ngFor="let buyer of LastenLoeschenCompanyFormArray.controls; let i = index">
                <h4 class="title-with-icon">Rechteinhaber Firma {{i + 1}}
                  <fa-icon [icon]="faTimes" class="icon" (click)="removeLastenLoeschenCompany(i)"></fa-icon>
                </h4>
                <app-company-data-form [parent]="buyer" [submitted]="submitted"></app-company-data-form>
              </div>
              <div>
                <button mat-raised-button color="primary" (click)="addLastenLoeschenOwnerComplex('person')">
                  Privatperson hinzufügen
                </button>
                <button mat-raised-button color="primary" (click)="addLastenLoeschenOwnerComplex('company')">
                  Firma hinzufügen
                </button>
                <br>
                <div>
                  <button mat-raised-button class="save-button" (click)="refreshLastenLoeschenOwners(true)">
                    Rechteinhaber speichern&nbsp;<fa-icon style="color: inherit; background-color: inherit;"
                      [icon]="faSave"></fa-icon>
                  </button>
                </div>
              </div>
              <br />
            </div>
          </div>
          <label>Bitte geben Sie die die C-LNR ein: </label>
          <div *ngFor="let clnr of wegLogicData.lastenLoeschenCLfdNr; let i = index; trackBy:trackByFn">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>C-LNR</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.lastenLoeschenCLfdNr[i]"
                name="lastenLoeschenCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <label>in EZ:</label>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>EZ:</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.lastenloeschenEzClnr[i]"
                name="lastenLoeschenEzCLaufendeNr{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>Bitte geben Sie die Art der Löschung an:</mat-label>
              <mat-select [(ngModel)]="wegLogicData.lastenloeschenType[i]" required name="lastenloeschenType{{i}}">
                <mat-option *ngFor="let option of lastenloeschenTypeOptionen" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label> Bitte geben Sie die Last laut Grundbucheintrag ein:</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.lastenloeschenGrundbuch[i]"
                name="lastenLoeschenGrundbuch{{i}}" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>

            <div *ngIf="wegLogicData.lastenloeschenType[i] === 'einverleibung'">
              <label>Wer verzichtet auf das Recht?</label>
              <br>
              <mat-form-field appearance="none" class="no-padding no-line" *ngIf="contractData.masterData.dritte.rightPersonList.length > 0" [class.validation-error]="submitted">
                <mat-label>Privatperson</mat-label>
                <mat-select [(ngModel)]="wegLogicData.LastenLoeschenBeteiligte[i].personArray" multiple name="LastenLoeschenperson{{i}}">
                  <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList"
                    [value]="rightOwner">
                    {{rightOwner.firstname + ' ' + rightOwner.lastname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <mat-form-field appearance="none" class="no-padding no-line" *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0" [class.validation-error]="submitted">
                <mat-label>Firma</mat-label>
                <mat-select [(ngModel)]="wegLogicData.LastenLoeschenBeteiligte[i].companyArray" multiple name="LastenLoeschencompany{{i}}">
                  <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList"
                    [value]="rightOwner">
                    {{rightOwner.companyName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button mat-raised-button color="primary" (click)="removelastenLoeschenEzCLfdNr(i)">
              Löschen
            </button>
            <br>
          </div>
          <button mat-raised-button color="primary" (click)="addlastenLoeschenEzCLfdNr()">
            C-LNR hinzufügen
          </button>
        </div>
      </div>


      <div class="radio-left-right mt-3">
        <div>
          <div class="row">
            <div class="col-6">
              <p>
                <label>Bestehen ausserbücherliche Lasten, welche nicht im Grundbuch erfasst sind?</label>
                <fa-icon [icon]="faInfoCircle" matTooltipPosition="above"
                  matTooltip="Manchmal sind Rechte Dritter nicht im Grundbuch eingetragen, wie beispielsweise nicht verbücherte Geldlasten, Bestandrechte (Mietverträge die weiterlaufen), Dienstbarkeiten oder sonstige Nutzungsrechte. Sollten solche Rechte anderer Personen bestehen, ist dies anzugeben.">
                </fa-icon>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.ausserbuecherlicheRechte" name="ausserbuecherlicheRechte" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button"
                  *ngFor="let ausserbuecherlicheRechte of ausserbuecherlicheRechteOptionen"
                  [value]="ausserbuecherlicheRechte">
                  {{ausserbuecherlicheRechte}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group><br />
            </div>
            <br>
          </div>

          <div *ngIf="wegLogicData.ausserbuecherlicheRechte == 'Ja'" class="row mt-5">
              <div class="col-md-12">
                <label class="w-50 text-bold text-yellow" id="example-radio-group-label">Bitte wählen Sie die eingeräumten ausserbücherlichen Rechte und
                  beschreiben
                  diese genauer: </label>
                  <br>
                  <br>
              </div>
              <div class="col-md-12">
                <section class="example-section text-leftt"
                  *ngFor="let ausserbuecherlicheRechte of ausserbuecherlicheRechteOptionenExtended; let i = index"
                  color="primary">
                  <mat-checkbox [checked]="wegLogicData.ausserbuecherlicheRechteDetail[i]"
                    (change)="removeTextFromAusserbuecherlicheRechteDetail($event, i)">
                    {{ ausserbuecherlicheRechte.viewValue }}
                  </mat-checkbox>
                  <textarea matInput placeholder="Bitte beschreiben Sie den Sachverhalt in 2-3 Sätzen:"
                    [(ngModel)]="wegLogicData.ausserbuecherlicheRechteDetail[i]"
                    name="ausserbuecherlicheRechteDetail + {{i}}" class="bitte-textarea">
                  </textarea>
                </section>
              </div>
            <br>
            </div>
          </div>
      </div>

      <div class="col-12">
        <div class="yellow-line-2 mt-4 mb-2"></div>
      </div>
      <div *ngIf="(this.KVzwischenAngehoerigenVisible)" class="radio-left-right row mt-3">
        <div class="col-6">
          <p>
            <label>Wird der Kaufvertrag zwischen Begünstigten im Sinne des § 26a GGG (nahe Angehörige - siehe Infobutton)
              abgeschlossen?</label>
            <fa-icon [icon]="faInfoCircle" matTooltipPosition="above"
              matTooltip="Bei Übertragung einer Liegenschaft an den Ehegatten oder eingetragenen Partner während aufrechter Ehe (Partnerschaft) oder im Zusammenhang mit der Auflösung der Ehe (Partnerschaft), an den Lebensgefährten, sofern die Lebensgefährten einen gemeinsamen Hauptwohnsitz haben oder hatten, an einen Verwandten oder Verschwägerten in gerader Linie, an ein Stief-, Wahl- oder Pflegekind oder deren Kinder, Ehegatten oder eingetragenen Partner, oder an Geschwister, Nichten oder Neffen des Überträgers liegt die Begünstigtenstellung vor. Bei Übertragung einer Liegenschaft aufgrund eines Vorgangs nach dem Umgründungssteuergesetz, BGBl. Nr. 699/1991, aufgrund eines Erwerbsvorgangs zwischen einer Gesellschaft und ihrem Gesellschafter oder aufgrund der Vereinigung aller Anteile einer Personengesellschaft.">
            </fa-icon>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.KVzwischenAngehoerigen" name="KVzwischenAngehoerigen" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button"
              *ngFor="let KVzwischenAngehoerigen of KVzwischenAngehoerigenOptionen" [value]="KVzwischenAngehoerigen">
              {{KVzwischenAngehoerigen}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>

        <div *ngIf="this.schenkung && wegLogicData.KVzwischenAngehoerigen === 'Ja'" class="col-md-12">
          <div class="col-12">
            <div class="yellow-line-2 mt-2 mb-2"></div>
          </div>
          <div class="row">
            <div class="col-6">
              <p>
                <label>Hat sich die Geschenknehmerseite die Ansprüche auf ihre Erbteile anrechnen zu lassen? </label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.Anrechnung" name="Anrechnung" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let Anrechnung of anrechnungOptionen"
                  [value]="Anrechnung">
                  {{Anrechnung}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf="contractData.contactData.contractOption === 'WEG' && !this.schenkung">
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2 sasas"></div>
        </div>
      </div>

      <div *ngIf="contractData.contactData.contractOption === 'WEG' && !this.schenkung" class="radio-left-right row mt-3">
        <div class="col-6">
          <p>
            <label>Wurde die ursprüngliche Baubewilligung des Gebäudes oder eines Teiles des Gebäudes vor dem 30.06.1953
              erteilt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.bewilligungVor08051945" name="bewilligungVor08051945" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button"
              *ngFor="let bewilligungVor08051945 of bewilligungVor08051945Optionen" [value]="bewilligungVor08051945">
              {{bewilligungVor08051945}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>
      <div class="col-12" *ngIf="contractData.contactData.contractOption === 'BBL' && !this.schenkung">
        <div class="yellow-line-2 mt-2 mb-2"></div>
      </div>


      <div *ngIf="contractData.contactData.contractOption === 'BBL' && !this.schenkung" class="radio-left-right mt-3">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wurde die ursprüngliche Baubewilligung des Gebäudes oder eines Teiles des Gebäudes vor dem 30.06.1953
                erteilt?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.bewilligungVor08051945" name="bewilligungVor08051945" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button"
                *ngFor="let bewilligungVor08051945 of bewilligungVor08051945Optionen" [value]="bewilligungVor08051945">
                {{bewilligungVor08051945}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="contractData.contactData.contractOption === 'UBL' || (contractData.contactData.contractOption === 'BBL' && LSBOOL[1])">
        <div class="yellow-line-2 mt-4 mb-2"></div>
      </div>
      <div class="radio-left-right" *ngIf="contractData.contactData.contractOption === 'UBL' || (contractData.contactData.contractOption === 'BBL' && LSBOOL[1])">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Welche Flächenwidmung liegt für die Liegenschaft vor?</label>
              <fa-icon (click)="openWidmungDlg()" [icon]="faUniversity"></fa-icon>
            </p>
          </div>
          <div class="col-6">
            <div>
              <mat-checkbox [(ngModel)]="wegLogicData.fragebauflaeche1" name="fragebauflaeche">
                Baufläche
              </mat-checkbox><br>

              <mat-checkbox [(ngModel)]="wegLogicData.fragebauerwartungsflaeche1" name="fragebauerwartungsflaeche">
                Bauerwartungsfläche
              </mat-checkbox><br>

              <mat-checkbox [(ngModel)]="wegLogicData.fragefreiflaeche1" name="fragefreiflaeche">
                sonstige Freifläche
              </mat-checkbox><br>

              <mat-checkbox [(ngModel)]="wegLogicData.fragelufflaeche1" name="fragelufflaeche">
                Land- & Forstwirtschaftliche Fläche
              </mat-checkbox><br>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div *ngIf="contractData.contactData.contractOption === 'WEG' && onlyOneBuyerAndSeller()" class="radio-left-right">
        <div class="col-12">
          <div class="yellow-line-2 mt-2 mb-2"></div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wird die gesamte Wohnungseigentumseinheit (Top) oder nur ein Anteil übertragen?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.EP" name="EP" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let EP of EPOptionen" [value]="EP.value">
                {{EP.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>

        <div *ngIf="wegLogicData.EP == 'halbes Top'">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Bisherige Eigentumsverhältnisse der Verkäuferseite (vor diesem Vertrag):</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.EPHelper" name="EPHelper" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let EP of EPHelperOptionen" [value]="EP.value">
                  {{EP.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div *ngIf="wegLogicData.EPHelper === 'haelfteeigentuemer' && wegLogicData.EP === 'halbes Top'">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Ist die Käuferseite bereits Eigentümerpartner?</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.EPPartner" name="EPPartner" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let EPPartner of EPPartnerOptionen"
                  [value]="EPPartner">
                  {{EPPartner}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div
          *ngIf="wegLogicData.EPPartner === 'Nein' && wegLogicData.EP === 'halbes Top' && wegLogicData.EPHelper == 'haelfteeigentuemer'">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Geben Sie die Daten des bisherigen Eigentümerpartners ein: </label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="selectedBuyerType" name="EPBuyer" (change)="onSelectionBuyerTypeChanged($event)" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let buyerContractPartnerType of contractPartnerTypes"
                  [value]="buyerContractPartnerType.value" color="primary">
                  {{ buyerContractPartnerType.viewValue }} </mat-radio-button><br />
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="selectedBuyerType == 'person'">
            <div *ngFor="let buyer of buyersPrivateFormArray.controls; let i = index">
              <h4 class="title-with-icon">Eigentümerpartner
              </h4>
              <app-person-data-form [parent]="buyer" [submitted]="submitted"></app-person-data-form>
            </div>
          </div>
          <div *ngIf="selectedBuyerType == 'company'">
            <div *ngFor="let buyer of buyersCompanyFormArray.controls; let i = index">
              <h4 class="title-with-icon">Eigentümerpartner
              </h4>
              <app-company-data-form [parent]="buyer" [submitted]="submitted"></app-company-data-form>
            </div>
          </div>

          <div *ngIf="checkEPListMaxOne()">
            <button mat-raised-button color="primary" (click)="addBuyer()">
              Eigentümerpartner hinzufügen
            </button>
          </div>
          <br />
        </div>
        <br>
      </div>


      <!-- sonstige Rechte -->
      <div *ngIf="contractData.contactData.contractOption === 'WEG'">
        <app-sonstige-rechte-logic id="SonstigeRechteWEG" IDIN="WEG" name="SonstigeRechteWEG" #SonstigeRechteWEG>
        </app-sonstige-rechte-logic>
      </div>
    </div>
    <!-- Not Miet Section END -->

    <!-- kfz Start -->
    <div *ngIf="this.contractData.primeType =='kfz'">
      <!-- Kaufpreis -->
      <div class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Welcher Kaufpreis wurde vereinbart (in EUR)?</mat-label>
          <input required matInput [ngModel]="wegLogicData.kaufpreis | looseCurrency:'EUR'" name="kaufpreis"
            id="kaufpreis" #kaufpreis [ngModelOptions]="{updateOn:'blur'}" (blur)="ratenchange()"
            (ngModelChange)="wegLogicData.kaufpreis=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
      </div>

      <div class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wird der Kaufpreis mit Raten abgezahlt?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.ratenJaNein" name="ratenjanein" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let janein of JaNeinOptionen" [value]="janein">
                {{janein}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="wegLogicData.ratenJaNein == 'Ja'" class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
            <mat-label>Bitte geben Sie die Anzahl der Raten ein.</mat-label>
            <input required matInput
              (blur)="ratenDatumChange(wegLogicData.datumRestoreUebergabe != undefined && wegLogicData.ratenJaNein == 'Ja' && wegLogicData.ratenAnzahl >0)"
              (change)="ratenchange()" [(ngModel)]="wegLogicData.ratenAnzahl" name="ratenanzahl" pattern="^[^\s]+(\s+[^\s]+)*$">
          </mat-form-field>
          <!-- Ausgabe der Höhe der einzelnen Raten -->
          <div *ngIf="wegLogicData.ratenHoehe != undefined" class="custom-kosten">
            <label>Ergibt {{wegLogicData.ratenHoehe}} pro Rate</label>
          </div>
        </div>
      </div>

      <!-- Datum der Fälligkeit der Kaufpreises -->
      <div style="display: flex;">
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie das Datum der Fälligkeit des vollen Kaufpreises.</mat-label>
          <input required
            (dateChange)="ratenDatumChange(wegLogicData.datumRestoreUebergabe != undefined && wegLogicData.ratenJaNein == 'Ja' && wegLogicData.ratenAnzahl >0)"
            matInput [matDatepicker]="picker" placeholder=" Datum wählen"
            [(ngModel)]="wegLogicData.datumRestoreUebergabe" matTooltipPosition="above"
            matTooltip="Jenes Datum, bis zu welchem der gesamte Kaufpreis bezahlt wird eingeben."
            name="datumRestoreUebergabe" pattern="^[^\s]+(\s+[^\s]+)*$">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <br>
      </div>

      <div
        *ngIf="wegLogicData.datumRestoreUebergabe != undefined && wegLogicData.ratenJaNein == 'Ja' && wegLogicData.ratenAnzahl >0"
        clas>
        <label>Datum der Fälligkeit der ersten Rate: {{wegLogicData.ersteRateDatum}}</label>
      </div>


      <h3>zum Kraftfahrzeug</h3>

      <!-- Marke -->
      <div>
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Bitte geben Sie die Marke des KFZ ein.</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.marke" name="marke" placeholder="zB. Suzuki" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
        <br>
      </div>

      <!-- Type -->
      <div>
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Bitte geben Sie die Type des KFZ ein.</mat-label>
          <input required matInput [(ngModel)]="wegLogicData.type" name="type"
            placeholder="zB. Swift; 4 Liter V4-Motor" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
        <br>
      </div>

      <!-- km-Stand -->
      <div>
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Bitte geben Sie den km-Stand ein.</mat-label>
          <input required matInput placeholder="zB. 10324,6" [(ngModel)]="wegLogicData.kmstand" name="kmstand" pattern="^[^\s]+(\s+[^\s]+)*$">
        </mat-form-field>
        <br>
      </div>

      <!-- Datum der erstzulassung -->
      <div style="display: flex;">
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [class.validation-error]="submitted">
          <mat-label>Bitte wählen Sie das Datum der Erstzulassung.</mat-label>
          <input required matInput [matDatepicker]="pickerone" placeholder=" Datum wählen"
            [(ngModel)]="wegLogicData.erstzulassungRestore" name="erstzulassung" pattern="^[^\s]+(\s+[^\s]+)*$">
          <mat-datepicker-toggle matSuffix [for]="pickerone"></mat-datepicker-toggle>
          <mat-datepicker #pickerone></mat-datepicker>
        </mat-form-field>
        <br>
      </div>


      <div class="custom-box">
        <label>Wir für einen bestimmten Zustand (Gew&auml;hrleistung) gehaftet?</label>
        <br />
        <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
          [(ngModel)]="wegLogicData.gewaehrleistung" name="gewaehr" [class.validation-error]="submitted">
          <mat-radio-button class="example-radio-button" *ngFor="let janein of JaNeinOptionen" [value]="janein">
            {{janein}}&emsp;
          </mat-radio-button>&emsp;
        </mat-radio-group><br />


        <div *ngIf="wegLogicData.gewaehrleistung == 'Ja'">
          <h4>Das KFZ befindet sich in folgendem Zustand:</h4>
          <br />
          <label>Mechanischer Zustand:</label>
          <br>

          <mat-radio-group style="display: flex; flex-direction: column;" required
            aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.mechanischerZustand" name="mech" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of mechanischerZustandOptionen"
              [value]="janein.value">
              {{janein.viewValue}}&emsp;<br>
            </mat-radio-button>&emsp;
          </mat-radio-group><br />
          <br>

          <label>Zustand der Karosserie:</label>
          <br>

          <mat-radio-group style="display: flex; flex-direction: column;" required
            aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.karosserieZustand" name="karros" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of karosserieZustandOptionen"
              [value]="janein.value">
              {{janein.viewValue}}&emsp;<br>
            </mat-radio-button>&emsp;
          </mat-radio-group><br />
          <br>

          <label>Zustand des Lack:</label>
          <br>

          <mat-radio-group style="display: flex; flex-direction: column;" required
            aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.lackZustand" name="lack" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of lackZustandOptionen"
              [value]="janein.value">
              {{janein.viewValue}}&emsp;<br>
            </mat-radio-button>&emsp;
          </mat-radio-group><br />
          <br>

          <label>Zustand des Innenraumes und Sonstiges:</label>
          <br>

          <mat-radio-group style="display: flex; flex-direction: column;" required
            aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.innenraumUndSonstigesZustand" name="innen" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of innenraumUndSontigesZustandOptionen"
              [value]="janein.value">
              {{janein.viewValue}}&emsp;<br>
            </mat-radio-button>&emsp;
          </mat-radio-group><br />
          <br>
          <label>Zustand der Elektronik:</label>
          <br>
          <mat-radio-group style="display: flex; flex-direction: column;" required
            aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.elektronikZustand" name="elekt" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let janein of elektronikZustandOptionen"
              [value]="janein.value">
              {{janein.viewValue}}&emsp;<br>
            </mat-radio-button>&emsp;
          </mat-radio-group><br/>
          <br>
    </div>
    </div>
  </div>
    <!-- kfz end -->
  </form>

  <div *ngIf="(contractData.contactData.contractOption === 'WEG' && primeType == 'miet')">
    <app-weglogic-immo-detail-miet-weg [submitted]="submitted" [contractOption]="contractData.contactData.contractOption" [parkplatz]="wegLogicData.parkplatz"></app-weglogic-immo-detail-miet-weg>
  </div>
  <div *ngIf="(contractData.contactData.contractOption === 'WEG' && primeType != 'miet')">
    <app-weglogic-immo-detail [submitted]="submitted" [contractOption]="contractData.contactData.contractOption" [parkplatz]="wegLogicData.parkplatz"></app-weglogic-immo-detail>
  </div>


  <div *ngIf="contractData.contactData.contractOption === 'BBL'
    || contractData.contactData.contractOption === 'UBL'">
    <app-immo-detail-logic [LSBOOL]="LSBOOL" [KBOOL]="this.kaeuferanteileBOOL" [submitted]="submitted"></app-immo-detail-logic>
  </div>

  <div *ngIf="contractData.primeType !='kfz'" class="mt-4">
    <div class="col-12">
      <div class="yellow-line mb-5">
      </div>
    </div>
    <!-- Vermittlungsprovision -->
  </div>
      <div *ngIf="!this.schenkung && !this.miet">
      <h3 style="margin-top:2% !important" class="mb-4">Vermittlungsprovision</h3>
      <div class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Soll eine allf&auml;llige Vermittlungsprovision treuh&auml;ndig abgewicklet werden?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="wegLogicData.fragevermittlungsprovision" name="fragevermittlungsprovision" [class.validation-error]="submitted">
              <mat-radio-button class="example-radio-button" *ngFor="let asdf of anrechnungOptionen" [value]="asdf">
                {{asdf}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>

        <div *ngIf="wegLogicData.fragevermittlungsprovision == 'Ja'">
          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="wegLogicData.vermittlungsprovision.wer" name="fragevermittlungsprovisionwer" [class.validation-error]="submitted">
            <mat-radio-button class="example-radio-button" *ngFor="let asdf of WerProvisionOptionen"
              [value]="asdf.value">
              {{asdf.viewValue}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
          <br>

          <div class="half-field"
            *ngIf="wegLogicData.vermittlungsprovision.wer == 'beides' || wegLogicData.vermittlungsprovision.wer == 'verkaeufer'">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>Provision Verk&auml;uferseite:</mat-label>
              <input required matInput [ngModel]="wegLogicData.vermittlungsprovision.verkaeuferin | looseCurrency:'EUR'"
                name="Kvermittlungsprovision" [ngModelOptions]="{updateOn:'blur'}"
                (ngModelChange)="wegLogicData.vermittlungsprovision.verkaeuferin=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
          </div>
          <div class="half-field"
            *ngIf="wegLogicData.vermittlungsprovision.wer == 'beides' || wegLogicData.vermittlungsprovision.wer == 'kaeufer'">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>Provision K&auml;uferseite:</mat-label>
              <input required matInput [ngModel]="wegLogicData.vermittlungsprovision.kaeuferin | looseCurrency:'EUR'"
                name="Kvermittlungsprovision" [ngModelOptions]="{updateOn:'blur'}"
                (ngModelChange)="wegLogicData.vermittlungsprovision.kaeuferin=$event" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
          </div>
          <div class="half-field">
            <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
              <mat-label>An wen ist die Provision zu bezahlen?</mat-label>
              <input required matInput [(ngModel)]="wegLogicData.vermittlungsprovision.makler" name="makler" pattern="^[^\s]+(\s+[^\s]+)*$">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="yellow-line mt-5"></div>
      </div>
    </div>

    <!-- Vertragskosten -->
    <div class="mt-5">
      <h3 style="margin-top:2% !important">Vertragskosten</h3>
      <!-- Infolabel zu den Vertragskosten -->
      <!-- werKostenVertragserrichtung -->
      <div *ngIf="!this.miet" class="mt-3 mb-4">
        <div *ngIf="!this.schenkung" class="radio-left-right">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Wer bezahlt Kosten der Vertragserrichtung?</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.werKostenVertragserrichtung" name="werKostenVertragserrichtung" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of MieterVermieterOptionenKauf"
                  [value]="asdf.value">
                  {{asdf.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div *ngIf="this.schenkung" class="custom-box radio-left-right">
          <div class="row">
            <div class="col-6">
              <p><label>Wer bezahlt Kosten der Vertragserrichtung?</label></p>
            </div>
            <div class="col-6">
              <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="wegLogicData.werKostenVertragserrichtung" name="werKostenVertragserrichtung" [class.validation-error]="submitted">
                <mat-radio-button class="example-radio-button" *ngFor="let asdf of MieterVermieterOptionenSchenk"
                  [value]="asdf.value">
                  {{asdf.viewValue}}&emsp;
                </mat-radio-button>&emsp;
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>

      <!-- Kosten der Vertragserrichtung -->
      <div class="col-md-6 offset-0 offset-sm-3 offset-md-3 offset-lg-3 offset-xl-3">
        <div *ngIf="this.miet">
          <div *ngIf="wegLogicData.mietObjType == 'unternehmenspacht' || wegLogicData.mietObjType == 'geschaeftsraum'" class="custom-kosten">
            <h3>Kosten der Vertragserrichtung:</h3>
            <div class="inner-table">
              <table class="mx-auto">
                <tr>
                  <td>{{'157.50'| currency:'EUR'}} Netto </td>
                </tr>
                <tr>
                  <td>{{'157.50' * 0.2 | currency:'EUR'}} USt </td>
                </tr>
                <tr>
                  <td> <h4>{{'157.50' * 1.2 | currency:'EUR'}} Brutto </h4> </td>
                </tr>
              </table>
            </div>
          </div>
          <div *ngIf="wegLogicData.mietObjType != 'unternehmenspacht' && wegLogicData.mietObjType != 'geschaeftsraum'" class="custom-kosten">
            <h3>Kosten der Vertragserrichtung:</h3>
            <div class="inner-table">
              <table class="mx-auto">
                <tr>
                  <td>{{'82.50'| currency:'EUR'}} Netto </td>
                </tr>
                <tr>
                  <td>{{'82.5' * 0.2 | currency:'EUR'}} USt</td>
                </tr>
                <tr>
                  <td><h4>{{'82.5' * 1.2 | currency:'EUR'}} Brutto</h4></td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div *ngIf="!this.schenkung && !this.miet">
          <div *ngIf="transformCurrencyToNumber(wegLogicData.kaufpreis) < 400000" class="custom-kosten">
            <h3>Kosten der Vertragserrichtung:</h3>
            <div class="inner-table">
              <table class="mx-auto">
                <tr>
                  <td>{{'2000'| currency:'EUR'}} Netto</td>
                </tr>
                <tr>
                  <td>{{'2000' * 0.2 | currency:'EUR'}} USt </td>
                </tr>
                <tr>
                  <td><h4>{{'2000' * 1.2 | currency:'EUR'}} Brutto</h4></td>
                </tr>
              </table>
            </div>
            <!-- Kosten der Vertragserrichtung: <br>
          Netto: {{'2000'| currency:'EUR'}}. <br>
          USt: {{'2000' * 0.2 | currency:'EUR'}} <br>
          Brutto: {{'2000' * 1.2 | currency:'EUR'}} <br> -->
          </div>

          <div *ngIf="transformCurrencyToNumber(wegLogicData.kaufpreis) >= 400000" class="custom-kosten">
            <h3 class="BIG000">Kosten der Vertragserrichtung:</h3>
            <div class="inner-table">
              <table class="mx-auto">
                <tr>
                  <td>{{transformCurrencyToNumber(wegLogicData.kaufpreis) * 0.005 | currency:'EUR'}} Netto</td>
                </tr>
                <tr>
                  <td>{{transformCurrencyToNumber(wegLogicData.kaufpreis) * 0.2* 0.005 | currency:'EUR'}} USt</td>
                </tr>
                <tr>
                  <td><h4>{{transformCurrencyToNumber(wegLogicData.kaufpreis) * 1.2* 0.005 | currency:'EUR'}} Brutto</h4></td>
                </tr>
              </table>
            </div>
            <!--
          Kosten der Vertragserrichtung: <br>
          Netto: {{transformCurrencyToNumber(wegLogicData.kaufpreis) * 0.005 |
          currency:'EUR'}}. <br>
          USt: {{transformCurrencyToNumber(wegLogicData.kaufpreis) * 0.2* 0.005 | currency:'EUR'}} <br>
          Brutto: {{transformCurrencyToNumber(wegLogicData.kaufpreis) * 1.2* 0.005 | currency:'EUR'}} <br> -->
          </div>
        </div>

        <div *ngIf="this.schenkung" class="custom-kosten">
          <h3>Kosten der Vertragserrichtung:</h3>
          <div class="inner-table">
            <table class="mx-auto">
              <tr>
                <td><b>Netto:</b></td>
                <td>{{'1500'| currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>USt:</b></td>
                <td>{{'1500' * 0.2 | currency:'EUR'}}</td>
              </tr>
              <tr>
                <td><b>Brutto:</b></td>
                <td>{{'1500' * 1.2 | currency:'EUR'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sonstiges -->

  <div class="container">
    <div class="col-12">
      <div class="yellow-line mb-5 mt-5"></div>
    </div>
    <h3 class="mt-3">Sonstiges</h3>
    <!-- <label *ngIf="contractData.primeType !='kfz'">Hinweise für den Vertragserrichter: <br></label> -->
    <textarea [placeholder]="contractData.primeType !== 'kfz' ? 'Hinweise für den Vertragserrichter:' : '' " class="textarea" required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="8" [(ngModel)]="wegLogicData.admin.hinweiseVertrag" name="irgendwasfad234"
      style="border:solid 1px #446688; width: 98% !important; background-color: #fff; padding: 2px;" pattern="^[^\s]+(\s+[^\s]+)*$">
    </textarea>
    <br>
    <br>
    <div>
      <label>
        Sonstiger Anhang:
        <input type="file" class="accent-color" accept="*"
          (change)="changeListener($event)" [(ngModel)]="wegLogicData.attachmentName">
      </label>
    </div>
    <br>
  </div>



  <!-- Checkboxen AGB etc pp -->
  <div class="checkbox-div text-left">
    <mat-checkbox [(ngModel)]="wegLogicData.admin.faggVerzicht" required style="margin: 0;" class="mat-checkbox-layout"
      name="helloworld21" [class.validation-error]="submitted">Ich verzichte auf Wideruffsrecht nach <a style="color: blue;" target="_blank" href="/assets/fagg.html">FAGG</a>.
    </mat-checkbox><br>
    <mat-checkbox [(ngModel)]="wegLogicData.admin.datenschutz" style="margin: 0;" required class="mat-checkbox-layout"
      name="helloworld22" [class.validation-error]="submitted">Ich
      stimme
      der
      <a style="color: blue;" target="_blank" href="/assets/datenschutz.html">Datenschutzerklärung</a> zu.
    </mat-checkbox><br>
    <mat-checkbox [(ngModel)]="wegLogicData.admin.agb" style="margin: 0;" required class="mat-checkbox-layout"
      name="helloworld23" [class.validation-error]="submitted">Ich stimme
      hiermit
      den
      <a style="color: blue;" target="_blank" href="/assets/agb.html">AGB</a> zu.
    </mat-checkbox>
  </div>

  <!-- <button class="float-left" mat-raised-button color="primary" (click)="saveDB()">
    Speichern
  </button>
  <br> -->
  <br>
  <div class="float-right" style="color: red" *ngIf="!contractForm.valid && contractForm.touched && this.NoVorschau">
    Bitte füllen Sie alle Felder aus!
  </div>
  <br>

  <div class="container">
    <div class="custom-weiter-div" style="border-top: 2px solid #cba857 !important">
      <button mat-raised-button color="primary" class="custom-weiter bg-button" (click)="gotoNext(contractForm, contractForm.valid )">
        Absenden
      </button>
      <label style="float: right;">
        <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
      </label>
    </div>
  </div>
