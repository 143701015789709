export const environment = {
  production: true,
  savetime:10000,
  password: "!ens2020$",
  // NodeAppUrl:"https://vertragsexpertaws.ens.gmbh",
  // NodeAppUrl:"https://stagingvertragsexpert.ens.gmbh",
  NodeAppUrl:"https://start.vertragsexpert.com",
  NodeAppUrl2:"http://kvgen.gorillacrm.cc/node",
  dburl:"https://vertragsexpert.com/wp-json/acf/v3/posts/",
};
